/** Constants to be used in components to be defined here so can be shared across application*/
export class AppConstants {
    // User plan types
    public static PLAN_TYPE_AGYIELD_PLUS = 1;
    public static PLAN_TYPE_AGYIELD_PRO = 2;
    public static PLAN_TYPE_ENTERPRISE_PRO = 3;
    public static PLAN_TYPE_ENTERPRISE_LITE = 7;
    public static PLAN_TYPE_ENTERPRISE_PLUS_PRO = 8;
    public static PLAN_TYPE_ENTERPRISE_PLUS_LITE = 9;
    public static PLAN_TYPE_MBP_USER = 12;
    public static PLAN_TYPE_MBP_MASTER = 11;
    public static PLAN_TYPE_BASIC_USER = 10;
    public static PLAN_TYPE_PRO_USER = 2;
    public static PLAN_TYPE_PLUS_USER = 1;
    public static POOL_ACCOUNTS_TITLE = 'Manage Bushel Program';

    // User Operation type
    public static USER_SINGLE_OPERATION = 'SO';
    public static USER_MULTIPLE_OPERATION = 'MO';

    // User roles
    public static USER_OPERATION_STAFF_USER = 'OUS';
    public static USER_OPERATION_ADMINISTRATOR = 'OAD';
    public static USER_AY_STAFF = 'STF';

    public static USER_AY_SALES = 'SLS';

    public static USER_AY_ADMIN = 'ADM';
    public static SOURCE_MANUAL = 'manual';

    // Crop Types
    public static CORN = '1';
    public static CANOLA = '8';
    public static COTTON = '7';
    public static SOYBEANS = '5';
    public static HRW = '2';
    public static SRW = '6';

    // Insurance types
    public static INSURANCE_TYPE_ARP = 1;
    public static INSURANCE_TYPE_ARP_HPE = 2;
    public static INSURANCE_TYPE_AYP = 3;
    public static INSURANCE_TYPE_RP_HPE = 4;
    public static INSURANCE_TYPE_RP = 5;
    public static INSURANCE_TYPE_YP = 6;
    public static INSURANCE_TYPE_STAX = 10;
    public static INSURANCE_TYPE_STAX_HPE = 11;

    public static TRANSACTION_OTHER_REVENUE = 8;
    // Transaction type
    public static CASH = 1;
    public static HTA = 2;
    public static BASIS = 3;
    public static PERCENTAGE = 4;
    public static FUTURE = 5;
    public static OPTION = 6;
    public static INDEMNITY = 7;
    public static OTHER = 8;
    public static CSO = 9;
    public static MINMAX = 10;
    public static MBP = 11;
    public static ACCUMULATOR = 12;
    public static TRANSACTION_CASH = 1;
    public static TRANSACTION_CUSTOMCONTRACT = 13;
    public static TRANSACTION_FIRMOFFER = 14;
    public static TRANSACTION_PERCENTAGE = 4;
    public static TRANSACTION_HTA = 2;
    public static TRANSACTION_BASIS = 3;
    public static TRANSACTION_MINMAX = 10;
    public static TRANSACTION_ACCUMULATOR = 12;
    public static TRANSACTION_MBP = 11;
    public static TRANSACTION_FUTURE = 5;
    public static TRANSACTION_OPTION = 6;
    public static TRANSACTION_CSO = 9;
    public static TRANSACTION_OTHER = 8;
    public static TRANSACTION_INDEMNITY = 7;
    public static TRANSACTION_CUSTOM_CONTRACT = 13;
    public static TRANSACTION_FIRM_OFFER = 14;
    public static PM12 = ' 12:00:00';

    // Crop Master
    public static SOYBEANS_MEAL_CROP_TYPE_ID = 9;
    public static CANOLA_CROP_TYPE_ID = 8;
    public static SOYBEANS_OIL_CROP_TYPE_ID = 10;

    // Publish strategy
    public static PUBLISH_STRATEGY_SUBJECT = 'Notification - Strategy published';
    public static PUBLISH_STRATEGY_DESCRIPTION = 'A new strategy has been published by AgYield, please see attachment to view strategy.';

    // Faced Name
    public static FACED_NAME_CUSTOM_CONTRACT = 'customContract';
    public static FACED_NAME_FIRM_OFFER = 'firmOffer';

    //total farm acer validation
    public static MAXIMUM_DIGIT = 5;
    public static MAXIMUM_TOTAL_FARM_ACER_DIGIT = 6; // we have consider 6 digit becuse (,) is consider 1 digit
    public static ATHENA_STRATEGY = 'Strategy';

    public static USERFULLNAMELENGTH = 30;

    //Pen constant
    public static PEN_PRODUCTION_UNIT = 'cwt'

    public static OUTLOOK_FILTER_NO_RECORD = 'No results found for the selected filters. Please change the filter criteria.';
    public static TWELVE_MONTHS = [
      { "id": 1, "name": "January" },
      { "id": 2, "name": "February" },
      { "id": 3, "name": "March" },
      { "id": 4, "name": "April" },
      { "id": 5, "name": "May" },
      { "id": 6, "name": "June" },
      { "id": 7, "name": "July" },
      { "id": 8, "name": "August" },
      { "id": 9, "name": "September" },
      { "id": 10, "name": "October" },
      { "id": 11, "name": "November" },
      { "id": 12, "name": "December" }
    ];


}