/** AppModule: This is bootstrap module.*/
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SidebarModule, BreadcrumbModule, HeaderModule, FooterModule } from '@coreui/angular';

// Import 3rd party components
import * as Sentry from '@sentry/browser';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DataTablesModule } from 'angular-datatables';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgOtpInputModule } from 'ng-otp-input';
import { AppComponent } from './app.component';
// Import Loader module which will be used to show and hide loader for entire application
import { LoaderModule } from './modules/loader/loader.module';

// Import containers
import { DefaultLayoutComponent } from './containers';

// Import Component
import { P404Component } from './modules/error/404.component';
import { P500Component } from './modules/error/500.component';

const APP_CONTAINERS = [DefaultLayoutComponent, SidebarComponent];

// Import routing module
import { AppRoutingModule } from './app-routing.module';

import { GlobalErrorHandler } from './modules/shared/services/error-handler.service';
import { ApiService } from './modules/shared/services/api.service';
import { HedgesDashboardSummaryComponent } from './modules/hedges-dashboard-summary/hedges-dashboard-summary.component';
import { NgxMaskDirective,  provideEnvironmentNgxMask } from 'ngx-mask';
import { SidebarComponent } from './containers/sidebar/sidebar.component';

// import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

// Registered the sentry
Sentry.init({
    // dsn: environment.sentryUrl
});

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SidebarModule,
        BreadcrumbModule,
        FooterModule,
        HeaderModule,
        // PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        NgxMaskDirective,
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoaderModule,
        NgbModule,
        DataTablesModule,
        AutocompleteLibModule,
        NgOtpInputModule,
        ReactiveFormsModule.withConfig({
            callSetDisabledState: 'whenDisabledForLegacyCode',
          }),
    ],
    declarations: [AppComponent, ...APP_CONTAINERS, P404Component, P500Component],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        // {
        //     provide: PERFECT_SCROLLBAR_CONFIG,
        //     useValue: PERFECT_SCROLLBAR_CONFIG
        // },
        ApiService,
        provideEnvironmentNgxMask(),
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
      ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
