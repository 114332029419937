/** ApiService is used to make ajax call :GET/POST*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NameValueData } from '../../../models/name.value.model';
import { HttpRequestOption } from '../../../models/http.request.option.model';
import { AlertMessage, ConfirmationMessage } from '../../../models/alert.message.model';
import { Util } from '../../../modules/shared/common/util';
import { ApiUrlConfig } from '../common/api.url';
import { Globals } from '../common/globals';
import { AppEnum } from '../common/enums';
import { ayObject } from '../interface/shared-interface';
import { LocalStorageService } from './webstorage.service';
import { apiList } from './apisForBearerAuthorization';

declare let app: any;

const APIEndpoint = environment.apiBaseUrl;

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    global: Globals;

    /** constructor - Http & Router are injected  */
    constructor(private http: HttpClient, private storageService: LocalStorageService) {
        this.global = Globals.getInstance();
        window.addEventListener('storage', (e) => {
            if (
                (e.key === AppEnum.windowStorage.change_customer || e.key === AppEnum.windowStorage.ag_change_customer) &&
                !document.hasFocus()
            ) {
                window.location.reload();
            }
        });
    }

    /** getServiceCall function will be used to do get ajax call on server. Input: model of HttpRequestOption */
    /** TODO: make this function private */
    getServiceCall(option: HttpRequestOption,isDownload = false, fileName?: string): Promise<any> {
        // Calling common function to get the request header
        const reqHeader = this.getRequestHeader(option);
        let reqOption: ayObject;
        reqOption = { headers: reqHeader };
        if(isDownload) {
            reqOption = { headers: reqHeader, responseType: 'Blob' };
        }
        
        // if requestOption is given then merge with headers
        if (option.requestOption) {
            reqOption = { ...reqOption, ...option.requestOption };
        }

        return new Promise((resolve, reject) => {
            this.addCsrfCode(option);
            const url = this.getFullUrl(option, 'GET');

            // Http Get Call
            this.http
                .get(url, reqOption)
                .toPromise()
                .then((response: any) => {
                        // Check if the response is a Blob (PDF)
                    if(isDownload){
                        this.handleBlobResponse(response, resolve,fileName);
                    }else{
                        this.handleSuccessResponse(response ? response : {}, resolve);
                    }
                })
                .catch((error: HttpErrorResponse) => {
                    // Handle the error response
                    reject(error);
                });
        });
    }
    /** postServiceCall function will be used to do post ajax call on server. Input: model of HttpRequestOption */
    postServiceCall(option: HttpRequestOption): Promise<any> {
        // Calling common function to get the request header
        this.addCsrfCode(option);
        const reqHeader = this.getRequestHeader(option);
        const reqOption = { headers: reqHeader };
        return new Promise((resolve, reject) => {
            // Get full Url as caller has to pass the relative url
            const url = this.getFullUrl(option, 'POST');
            // Http Post Call
            this.http
                .post(url, option.postData, reqOption)
                .toPromise()
                .then((response) => {
                    this.handleSuccessResponse(response ? response : {}, resolve);
                })
                .catch((error: HttpErrorResponse) => {
                    // Handle the error response
                    reject(error);
                });
        });
    }

    /** downloadServiceCall function will be used to do download any doc on server. Input: model of HttpRequestOption */
    downloadServiceCall(option: HttpRequestOption): Promise<any> {
        // Calling common function to get the request header
        return new Promise(() => {
            // Get full Url as caller has to pass the relative url
            const url = this.getFullUrl(option, 'GET');
            window.open(url, '_blank');
        });
    }

    /** getFullUrl function will be used to get the API full Url for the given relative Url */
    private getFullUrl(option: HttpRequestOption, methodType: string): string {
        let fullUrl = '';
        // If relative Url is start with '/' then removed the '/' as apiBaseUrl contains the '/' at the end
        if (option.url.substring(0, 1) === '/') {
            fullUrl = environment.apiBaseUrl + option.url.substring(1);
        } else {
            fullUrl = environment.apiBaseUrl + option.url;
        }

        return this.appendParamValues(fullUrl, methodType, option.getData);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private appendParamValues(fullUrl: string, method: string, _param: object) {
        const fullParamObject: ayObject = _param;
        for (const key in fullParamObject) {
            if (fullParamObject.hasOwnProperty(key)) {
                const extraParam = key + '=' + fullParamObject[key];
                fullUrl = this.addParameterToURL(fullUrl, extraParam);
            }
        }

        return fullUrl;
    }

    /** appendParams function will be used to append oauthParams in url  */
    addParameterToURL(_url: string, param: string): string {
        _url += (_url.split('?')[1] ? '&' : '?') + param;
        return _url;
    }

    /** getOauthParams function will be used to get the oauthParams based on api call  */
    getOauthParams(requestType: string = ''): ayObject {
        const time = new Date();
        const oa_timestamp = (Math.floor(time.getTime() / 1000) as any) as string; // current timezone
        const oa_nonce = Math.random().toString(36).substring(2, 8); // random string generate of any len, we are using 8 chars
        let oauthParams: ayObject = {};
        if (requestType === 'login') {
            oauthParams = {
                oauth_signature_method: 'HMAC-SHA1',
                oauth_version: '1.0',
                oauth_timestamp: oa_timestamp,
                oauth_nonce: oa_nonce
            };
        } else if (requestType === 'accessToken') {
            oauthParams = {
                oauth_token: this.storageService.get('oauth_token'),
                oauth_signature_method: 'HMAC-SHA1',
                oauth_version: '1.0',
                oauth_timestamp: oa_timestamp,
                oauth_nonce: oa_nonce,
                oauth_verifier: this.storageService.get('oauth_verifier'),
                userId: this.storageService.get('userId')
            };
        } else {
            oauthParams = {
                oauth_token: this.storageService.get('oauth_token'),
                oauth_token_secret: this.storageService.get('oauth_token_secret'),
                oauth_signature_method: 'HMAC-SHA1',
                oauth_version: '1.0',
                oauth_timestamp: oa_timestamp,
                oauth_nonce: oa_nonce
            };
        }

        if (this.storageService.get('key')) {
            oauthParams.oauth_consumer_key = this.storageService.get('key');
        }

        return oauthParams;
    }

    /** getRequestHeader function will be used to get the request header that will be passed to any AJAX call */
    private getRequestHeader(option: HttpRequestOption) {
        // Get the accessToken from the storage...
        const allowUrl = this.filterAPIForAuthorization(option.url);
        const notAllowUrl = this.notAllowUrl(option.url);

        const accessToken = this.storageService.get(environment.authTokenKey);

        // Create the request header - adding Content-Type in request header that caller has passed
        const headersObject: ayObject = {};
        const httpHead: ayObject = { 'Content-Type': option.contentType };

        // headers = headers.append('Content-Type', option.contentType);
        headersObject['Content-Type'] = option.contentType;
        // Check access token if not there - pass the request headers with Authorization
       // if (accessToken !== undefined && accessToken !== null && accessToken.length > 0 /*&& allowUrl*/) {
        if (accessToken !== undefined && accessToken !== null && accessToken.length > 0 && !notAllowUrl) {
            delete httpHead.Authorization;
            // Bearer authentication implemented at server side...
            const combinedToken = 'Bearer ' + accessToken;
            headersObject.Authorization = combinedToken;
        }

        let headers = new HttpHeaders(httpHead);

        // In case if caller has passed any additional header that will be added in request header..
        if (option.headers !== undefined && option.headers !== null) {
            option.headers.forEach((headerItem: NameValueData) => {
                headersObject[headerItem.name] = headerItem.value;
            });
        }
        headers = new HttpHeaders(headersObject);
        return headers;
    }

    /**
     * this function will be called when logout and clear all data
     */
    private clearAllData(errorCode = 0) {
        this.storageService.remove('userId');
        window.localStorage.setItem('change_customer', '');
        this.storageService.remove('change_customer');
        Util.hideLoading();
    }

    showConfirmationMessage(errorCode = 0): void {
        const confirmMessage = new ConfirmationMessage();
        confirmMessage.type = AlertMessage.Type.warning;
        confirmMessage.title = 'Oops!!';
        confirmMessage.isShowCancelButton = false;
        if (Number(errorCode) === 7000) {
            confirmMessage.text = 'Session has expired please login again.';
        }
        if (Number(errorCode) === 9111) {
            confirmMessage.text = 'Your account is deactivated. Please contact administrator.';
        }
        if (Number(errorCode) === 7001) {
            confirmMessage.allowOutsideClick = false;
            confirmMessage.text = 'Token has expired please try again.';
        }
        confirmMessage.confirmButtonText = 'Ok';
        confirmMessage.okCallBack = async () => {
            this.global.setVal('isLogout', 1);
            location.reload();
        };
        confirmMessage.cancelCallBack = async () => {
            this.global.setVal('isLogout', 1);
            location.reload();
        };
        Util.showConfirmationDialog(confirmMessage);
    }

    public getCsrfCode(formName: string): void {
        // Create the HttpRequestOption
        const reqOpt: HttpRequestOption = new HttpRequestOption();
        reqOpt.url = ApiUrlConfig.CSRF.getCsrfCode;

        reqOpt.getData = {
            formName
        };

        this.getServiceCall(reqOpt)
            .then((result) => {
                this.global.setVal(reqOpt.getData.formName, result);
            })
            .catch(() => {});
    }

    private addCsrfCode(option: HttpRequestOption) {
        try {
            if ('formName' in option.postData) {
                const csrfData = this.global.getVal(option.postData.formName);
                option.postData.csrfCode = csrfData.csrf_token;
                option.postData.timestamp = csrfData.timestamp;
            }
        } catch (error) {}

        try {
            if ('formName' in option.getData) {
                const csrfData = this.global.getVal(option.getData.formName);
                option.getData.csrfCode = csrfData.csrf_token;
                option.getData.timestamp = csrfData.timestamp;
            }
        } catch (error) {}
    }
    
    showAlert(message: string = ''): void {
      Util.hideLoading();
      const confirmMessage = new ConfirmationMessage();
      confirmMessage.type = AlertMessage.Type.warning;
      confirmMessage.title = 'Oops!!';
      confirmMessage.allowOutsideClick = true;
      confirmMessage.text = message;
      confirmMessage.confirmButtonText = 'Ok';
      confirmMessage.isShowCancelButton = false;
      Util.showConfirmationDialog(confirmMessage);
  }

    // handle response for successful get/post request
    private handleSuccessResponse(response: ayObject, resolve: any): void {
        // Handle the success response
        if (response === null) {
            resolve(response);
        } else if (Number(response.code) === 9111 || Number(response.code) === 7000 || Number(response.code) === 7001) {
            this.clearAllData(response.code);
            this.showConfirmationMessage(response.code);
        } else if (Number(response.code) === 7002) {
            this.showAlert(response.status);
        } else {
            resolve(response);
        }
    }

    // filter api for authorization
    private filterAPIForAuthorization(url: string) {
        const urlSegments = url.split('/');
        let res = false;
        if (urlSegments.length === 2) {
            const requestClass = urlSegments[0].trim();
            const requestMethod = urlSegments[1].trim();
            if (apiList[requestClass] && requestMethod && apiList[requestClass].indexOf(requestMethod) > -1) {
                res = true;
            }
        }
        return res;
    }
    // filter api for authorization
    private notAllowUrl(url: string) {
        const urlSegments = url.split('/');
        let res = false;
        if (urlSegments.length === 2) {
            //const requestClass = urlSegments[0].trim();
            const requestMethod = urlSegments[1].trim();
            if (requestMethod === 'getOtp') {
                res = true;
            }
        }
        return res;
    }
    /**
     * Function to set request options and call service with get method
     *
     * @param url
     * @param params
     * @param isDownload
     * @param requestOption
     * @returns any
     * @author Manesh Raval
     */
    callGetApi(url: string, params: ayObject, isDownload: boolean = false, fileName?: string , requestOption: any = {} ): any {
        const reqOpt: HttpRequestOption = new HttpRequestOption();
        reqOpt.url = url;
        reqOpt.getData = params;

        // If requestOptions passed in argument then set the options and text/plain for contentType
        if (requestOption) {
            reqOpt.requestOption = requestOption;
            reqOpt.contentType = 'text/plain';
        }

        // If isDownload set then return blank object call download service
        if (isDownload) {
            params.userId = app.userId;
            reqOpt.getData = params;
            Object.keys(reqOpt.getData).sort().reverse();
            this.getServiceCall(reqOpt,isDownload, fileName);
            return {};
        } else {
            reqOpt.getData = params;
            return this.getServiceCall(reqOpt);
        }
    }

    /**
     * Function to set request options and call service with post method
     *
     * @param url
     * @param params
     * @returns any
     * @author Manesh Raval
     */
    callPostApi(url: string, params: ayObject): any {
        const reqOpt: HttpRequestOption = new HttpRequestOption();
        reqOpt.url = url;
        reqOpt.postData = params;
        return this.postServiceCall(reqOpt);
    }

    /**
     * Function to upload a file to server.
     *
     * @param url
     * @param param
     * @returns any
     * @author Manesh Raval
     */
    uploadFile(url: string, param: File, otherParams: ayObject = {}): any {
        const formData: FormData = new FormData();
        formData.append('fileKey', param, param.name);

        if (Object.keys(otherParams).length > 0) {
            for (const key in otherParams) {
                formData.append(key, otherParams[key]);
            }
        }

        const headersObject: ayObject = {};
        headersObject.Authorization = 'Bearer ' + this.storageService.get(environment.authTokenKey);

        let reqHeader = new HttpHeaders(headersObject);
        
        let reqOption: ayObject;
        reqOption = { headers: reqHeader };

        return this.http.post<any>(APIEndpoint + url, formData, reqOption );
    }
    private handleBlobResponse(blob: Blob, resolve: Function ,fileName=''): void {
        // Handle the Blob (PDF) response here
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = 'Agyield_'+fileName; // You can customize the filename
        a.click();
        window.URL.revokeObjectURL(url);
    
        resolve(); // Resolve the promise once the download is initiated
      }
    
     
    
}